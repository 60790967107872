/* contact us */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1rem;
  margin: 3rem auto;
  border-radius: 0.5rem;
  border: 1px solid #0a3161;
  width: 80%;
}

.contact-form .form-example {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.contact-form label {
  margin-bottom: 0.5rem;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  padding: 0.5rem;
  border: 1px solid #0a3161;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.contact-form input[type="submit"] {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #0a3161;
  font-size: 1rem;
  cursor: pointer;
}

.contact-form input[type="submit"]:hover {
  background-color: #b31942;
}

/* ? & text color when typing */
/* .form-example input,
.form-example textarea {
  background-color: #000;
} */

/* page-footer */
.main-logo {
  width: 70px;
}

.page-footer {
  padding: 1rem 1rem;
  position: relative;
  padding-left: 0px;
  margin-top: 30px;
  width: 750px;
}

.page-footer-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  max-width: 1440px;
}

.page-footer,
.page-footer a {
  color: #fff;
}

.page-footer-logo-col p {
  margin-top: 0;
  max-width: 20ch;
  color: #0a3161;
  font-weight: 700;
  font-size: 21px;
}

.page-footer-legal-text {
  font-size: var(--type-tiny-font-size);
  margin: 0;
}

.footer-nav-heading {
  font: var(--type-smaller-font-size);
  margin: 0 0 0.5rem;
  color: #0a3161;
}

.footer-nav-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-nav-item {
  font-size: var(--type-smaller-font-size);
}

/* ? can not change black when switch dark mode */
.footer-nav-item a {
  color: #fff;
}

@media screen and (min-width: 480px) {
  .page-footer-grid {
    display: grid;
    gap: 1rem;
    grid-template-areas: "logo  . " "nav1  nav2" "nav3  nav4" "nav5 nav6" "moz   moz" "legal legal";
    grid-template-columns: 1fr 1fr;
  }

  .page-footer-logo-col {
    grid-area: logo;
  }

  .page-footer-nav-col-1 {
    grid-area: nav1;
  }

  .page-footer-nav-col-2 {
    grid-area: nav2;
  }

  .page-footer-nav-col-3 {
    grid-area: nav3;
  }

  .page-footer-nav-col-4 {
    grid-area: nav4;
  }

  .page-footer-nav-col-5 {
    grid-area: nav5;
  }

  .page-footer-app-col {
    grid-area: app;
  }

  .page-footer-moz {
    grid-area: moz;
  }

  .page-footer-legal {
    grid-area: legal;
  }

  .page-footer-app-list {
    flex-direction: row;
  }

  .page-footer-app-dl img,
  .page-footer-app-dl svg {
    height: 38px;
  }
}

@media screen and (min-width: 768px) {
  .page-footer-grid {
    gap: 1rem;
    grid-template-areas: "logo  nav1  nav2  nav3  nav4" "moz   moz   moz   moz   moz" "legal legal legal legal  . ";
    grid-template-columns: minmax(260px, 2fr) repeat(4, minmax(0, 1fr));
  }

  .page-footer-app-list {
    flex-direction: column;
  }

  .page-footer-app-dl img,
  .page-footer-app-dl svg {
    height: auto;
  }
}
