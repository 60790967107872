/* homepage */
.homepage {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: clip;
  overflow-y: visible;
  position: relative;
  width: 100%;
  padding-top: 40px;
  max-width: 750px;
}

.homepage > :first-child {
  margin-top: 0;
}

.main-wrapper {
  display: grid;
  grid-template-columns: 306.283px 765.717px 240px;
  line-height: 30px;
  margin-bottom: 0px;
  margin-right: 48px;
  margin-top: 0px;
  max-width: 1440px;
  padding-left: 5px;
  column-gap: 48px;
  row-gap: 48px;
}

/*  1 Welcome  */
.homepage-hero h5 {
  margin: 0;
}

.homepage-hero {
  display: grid;
  grid-template-rows: fit-content(24rem);
  width: 100%;
}

.homepage-hero section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  grid-column: 1;
  grid-row: 1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 47rem;
  padding: 0 1rem;
}

.homepage-hero section h1 {
  color: #b31942;
  font-size: 2.2rem;
  font-weight: 200;
  letter-spacing: -0.5px;
  line-height: 10%;
  margin: 0;
}

.homepage-hero section h3 {
  font-size: 27px;
  color: "#0A3161";
  font-weight: 700;
}

@media screen and (min-width: 480px) {
  .homepage-hero section h1 {
    font-size: 1.5rem;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 768px) {
  .homepage-hero section h1 {
    font-size: 2rem;
    flex-wrap: wrap;
  }
}

.homepage-hero section p {
  font-weight: 300;
}

/* 2 our projects & 5 Cooperation */
/* featured-articles */
.featured-articles {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.featured-articles h2 {
  margin-top: 15px;
  font-size: 25px;
  color: #b31942;
}

.featured-articles h2 a {
  color: #b31942;
}

/* tile-container */
.featured-articles .tile-container {
  display: grid;
  gap: 1rem;
  grid-auto-flow: row;
  max-width: 47rem;
}

.featured-articles .tile-container a {
  color: #0a3161;
}

@media screen and (min-width: 768px) {
  .featured-articles .tile-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* article-tile */
.featured-articles .article-tile {
  display: flex;
  flex-direction: column;
  border: 1px solid #0a3161;
  border-radius: 0.5rem;
  margin-bottom: 5px;
  padding: 1rem;
}

.featured-articles .article-tile .tile-tag {
  font-weight: 600;
}

/* tile-title */
.featured-articles .article-tile .tile-title {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  font-weight: 600;
  margin: 0 0 1rem;
  color: #0a3161;
}

.featured-articles .article-tile .tile-title a:focus,
.featured-articles .article-tile .tile-title a:hover {
  text-decoration: underline;
}

.featured-articles .article-tile p {
  margin: 0;
  overflow: hidden;
  position: relative;
}

.featured-articles .article-tile p:after {
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.featured-articles .article-tile p:dir(rtl):after {
  left: 0;
  right: auto;
}

@media screen and (min-width: 768px) {
  .featured-articles .article-tile p {
    /* min-height: calc(3em * var(--base-line-height)); */
  }
}

/* 3 Domains for Startup */
.latest-news {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 47rem;
  padding: 0 1rem;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .latest-news {
    padding: 0;
  }
}

.latest-news h2 a {
  margin-top: 1rem;
  color: #b31942;
  font-size: 25px;
}

.latest-news h2 {
  margin-top: 1rem;
  color: #b31942;
  font-size: 23px;
}

.latest-news a {
  color: #0a3161;
  font-size: 17px;
}

.latest-news h5 {
  margin: 0;
}

.latest-news .news-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.7rem;
  border: 1px solid #0a3161;
  border-radius: 0.5rem;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .latest-news .news-item {
    flex-direction: revert;
  }
}

.latest-news .news-item .request-date {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 175%;
}

.latest-news .news-item .news-date {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 175%;
}

.latest-news .news-title {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  margin: 0;
}

.latest-news .news-title a:hover {
  color: var(--text-primary);
  text-decoration: underline;
}

/* 4 Domains for sale */
.recent-contributions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 47rem;
  padding: 0 1rem;
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .recent-contributions {
    padding: 0;
  }
}

.recent-contributions h2 a {
  margin-top: 1rem;
  color: #b31942;
  font-size: 25px;
}

.recent-contributions h2 {
  margin-top: 1rem;
  color: #b31942;
  font-size: 23px;
}

/* contact btn */
.recent-contributions h3 {
  color: #0a3161;
  margin: 0;
  font-size: 19px;
  padding-left: 45px;
}

.recent-contributions a {
  color: #0a3161;
  font-size: 17px;
}

.recent-contributions h5 {
  margin: 0;
}

.recent-contributions .request-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.7rem;
  border: 1px solid #0a3161;
  border-radius: 0.5rem;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .recent-contributions .request-item {
    flex-direction: revert;
  }
}

.recent-contributions .request-item .news-date {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 175%;
}

.recent-contributions .request-item .request-date {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 175%;
}

.recent-contributions .request-title {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  margin: 0;
  overflow-wrap: break-word;
}

.recent-contributions .request-title a:hover {
  text-decoration: underline;
}

/* section */
section.place {
  display: flex;
  margin: 1rem 0;
}

section.place a,
section.place a:not(.button) {
  text-decoration: none;
}

section.place.side {
  flex-direction: column;
  max-width: 12rem;
  min-height: 20rem;
}

section.place.top {
  display: grid;
  align-items: center;
  font-size: 0.625rem;
  margin: 0 auto;
  width: 100%;
}

@media screen and (min-width: 768px) {
  section.place.top {
    grid-template-columns: fit-content(100%) auto fit-content(100%);
  }
}

section.place.hp-main {
  margin: 0;
  width: 100%;
}

section.place.hp-main a {
  display: flex;
  justify-content: center;
  width: 100%;
}

section.place.hp-main a img {
  height: auto;
}
